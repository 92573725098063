import React, { AnchorHTMLAttributes, FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Image from 'gatsby-image';
import { Interpolation } from 'emotion';
import styled from 'react-emotion';
import facepaint from 'facepaint';

export const breakpoints = [
  '@media (min-width: 600px)',
  '@media (min-width: 900px)',
  '@media (min-width: 1200px)',
];

export const responsive = facepaint(breakpoints);

// '#069FF5', // blue
// '#248AEA', // blue
// '#0564B2', // blue
// '#055DA0', // blue

const ExternalLink = styled('a')({
  color: '#055DA0',
  textDecoration: 'none',
  outlineOffset: '0rem',
  outline: '1px solid #248AEA00',

  '&:hover, &:focus': {
    outline: '1px solid #248AEA',
    outlineOffset: '0.3rem',
  },

  '&:active': {
    outline: '1px solid #0564B2',
    outlineOffset: '0.3rem',
  },
});

export const A: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = (props) => (
  <ExternalLink target="_blank" rel="nofollow noopener noreferrer" {...props} />
);

export const Link = styled(GatsbyLink)({
  color: '#055DA0',
  textDecoration: 'none',
  outlineOffset: '0rem',
  outline: '1px solid #248AEA00',

  '&:hover, &:focus': {
    outline: '1px solid #248AEA',
    outlineOffset: '0.3rem',
  },

  '&:active': {
    outline: '1px solid #248AEA',
    outlineOffset: '0.3rem',
  },
});

export interface ImgProps {
  rotate?: number;
}

export const Img = styled(Image)<ImgProps>(
  {
    background: 'white',
    padding: '2rem',
    marginBottom: '2rem',
    boxShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.2)',
  },
  ({ rotate }) =>
    rotate
      ? { transform: `rotate(${rotate}deg)` }
      : responsive({
          transform: ['rotate(-2deg)', 'rotate(2deg)'],
        })
);

export const Amatic = styled('div')({
  fontFamily:
    'Amatic SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  fontSize: '2rem',
});

export interface CenteredProps {
  centered?: boolean;
}

export const centered = ({ centered }: CenteredProps): Interpolation =>
  centered ? { textAlign: 'center' } : null;

export interface StackProps {
  stack?: boolean;
}

export const stack = ({ stack }: StackProps) =>
  stack ? { marginBottom: '1rem' } : { marginBottom: 0 };

const textVariants = {
  large: responsive({
    fontSize: ['3rem', '3rem', '4rem'],
    fontWeight: 400,
    margin: 0,
    marginBottom: '1rem',
  }),
  medium: responsive({
    fontSize: ['1.5rem', '2rem', '3rem'],
    fontWeight: 400,
    margin: 0,
    marginBottom: '1rem',
  }),
  small: responsive({
    fontSize: ['1.5rem', '1.5rem', '2rem'],
    fontWeight: 800,
    margin: 0,
    marginBottom: '1rem',
  }),
  text: responsive({
    fontSize: ['2rem', '3rem', '4rem'],
    fontWeight: 400,
    margin: 0,
    marginBottom: '1rem',
  }),
};

interface TextVariantProps {
  variant?: keyof typeof textVariants;
}

const textVariant = ({ variant }: TextVariantProps) =>
  (variant && textVariants[variant]) || {};

export const H1 = styled('h1')<TextVariantProps & CenteredProps & StackProps>(
  textVariant,
  centered,
  stack
);
H1.defaultProps = { variant: 'large' };

export const H2 = styled('h1')<TextVariantProps & CenteredProps & StackProps>(
  textVariant,
  centered,
  stack
);
H2.defaultProps = { variant: 'medium', stack: true };

export const H3 = styled('h3')<TextVariantProps & CenteredProps & StackProps>(
  textVariant,
  centered,
  stack
);
H3.defaultProps = { variant: 'small', stack: true };

export const H4 = styled('h4')<TextVariantProps & CenteredProps & StackProps>(
  textVariant,
  centered,
  stack
);
H3.defaultProps = { variant: 'small', stack: true };

export const P = styled('p')<TextVariantProps & CenteredProps & StackProps>(
  textVariant,
  centered,
  stack
);
P.defaultProps = { variant: 'text', stack: true };

export const Ul = styled('ul')({});

export const Li = styled('li')({});

export const Inline = styled('p')<TextVariantProps & CenteredProps>(
  { display: 'inline' },
  textVariant
);
Inline.defaultProps = { variant: 'text' };

export const NoBreak = styled('span')({
  whiteSpace: 'nowrap',
});

export const Section = styled('section')(
  responsive({
    paddingTop: ['2rem', '4rem'],
    paddingBottom: ['2rem', '4rem'],
  })
);

// '#069FF5', // blue
// '#248AEA', // blue
// '#0564B2', // blue
// '#055DA0', // blue

export const borderColors = [
  '#069FF5', // blue
  '#248AEA', // blue
  '#0564B2', // blue
  '#055DA0', // blue
];

export const Border = styled('div')(
  responsive({
    padding: ['0 0.25rem', '0 0.5rem 0.5rem', '0rem 1rem 1rem'],
    background: `linear-gradient(${borderColors.join(', ')})`,
  })
);

export const Background = styled('div')(
  responsive({
    backgroundColor: '#FFFFFF',
    borderRadius: ['1rem', '2rem', '4rem'],
  })
);

export const Content = styled('main')(
  responsive({
    // backgroundColor: '#FFFFFF',
    // borderRadius: ['1rem', '2rem', '4rem'],
    margin: '0 auto',
    maxWidth: '50rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    paddingLeft: ['2rem', '4rem', '8rem'],
    paddingRight: ['2rem', '4rem', '8rem'],
  })
);

export const Center = styled('div')({
  textAlign: 'center',
});

export interface IframeProps {
  width: string | number | Array<string | number | null | undefined>;
  height: string | number | Array<string | number | null | undefined>;
}

// @ts-ignore
export const Iframe = styled(({ width, height, ...props }) => (
  <iframe {...props} />
))<IframeProps>(
  {
    border: '1px solid transparent',
  },
  ({ width }) => width && responsive({ width }),
  ({ height }) => height && responsive({ height })
);

export const Form = styled('form')({});

export const Fieldset = styled('fieldset')({
  border: '1px solid black',
  '> * + *': { marginTop: '1rem' },
});

export const FormField = styled('label')({
  display: 'block',
});

export const FormActions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const Label = styled('label')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '> * + *': { marginLeft: '1rem' },
  '& + &': { marginTop: '1rem' },
});

export const Input = styled('input')(
  {
    display: 'block',
    width: '100%',
    border: '1px solid transparent',
    borderBottom: '1px solid black',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    outline: '1px solid transparent',

    '&:hover, &:focus': {
      borderBottomColor: '#248AEA',
      outline: '1px solid #248AEA',
    },

    '&:active': {
      borderBottomColor: '#0564B2',
      outline: '1px solid #0564B2',
    },
  },
  ({ type }) =>
    ['checkbox', 'radio'].includes(type) ? { width: 'auto' } : null
);

export const Textarea = Input.withComponent('textarea');

export const Button = styled('button')({
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  border: '1px solid black',
  background: 'white',
  color: 'black',

  '&:hover, &:focus': {
    color: '#248AEA',
    borderColor: '#248AEA',
    outline: '1px solid currentColor',
  },

  '&:active': {
    color: '#0564B2',
    borderColor: '#0564B2',
    outline: '1px solid currentColor',
  },
});

export const Header = styled('header')({});
export const Footer = styled('footer')({});

export const backgroundColors = [
  '#069FF5', // blue
  '#248AEA', // blue
  '#0564B2', // blue
  '#055DA0', // blue
  '#D0862E', // orange
];

export interface DividerProps {
  index: number;
}

export const Divider = styled('hr')<DividerProps>(
  {
    width: '50%',
    position: 'relative',
    border: '0px solid transparent',
    height: '1px',
    overflow: 'visible',
    marginBottom: '2rem',
  },
  ({ index }) => ({
    backgroundColor: backgroundColors[index],
    '&::after': {
      color: backgroundColors[index],
      position: 'absolute',
      textAlign: 'center',
      content: '"#"',
      fontSize: '2rem',
      top: '-1.25rem',
      left: '50%',
      right: '50%',
      margin: 0,
      marginLeft: '-50vw',
      marginRight: '-50vw',
    },
  })
);

export interface PhotoProps {
  index: number;
}

export const Photo = styled('div')<PhotoProps>(
  {
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',

    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  ({ index }) => ({ backgroundColor: backgroundColors[index] })
);

export class ScrollTop extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}
