import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { injectGlobal } from 'emotion';
import styled from 'react-emotion';
import {
  Border,
  Background,
  Content,
  Section,
  Center,
  H1,
  H2,
  H3,
  H4,
  P,
  A,
  Ul,
  Li,
  Link,
  Iframe,
  Img,
  Inline,
  Photo,
  Label,
  Input,
  Textarea,
  Form,
  Fieldset,
  FormActions,
  Button,
  Divider,
  NoBreak,
  Amatic,
} from '../components';

injectGlobal({
  'html, body': {
    margin: 0,
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
    fontSize: '16px',
    background: '#069FF5',
  },
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    transition: 'all 0.2s ease-out',
  },
});

interface ID {
  id: string;
}

const Intro: FC<ID> = (props) => (
  <Section {...props}>
    <div
      style={{
        background: 'white',
        padding: '2rem',
        boxShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.2)',
        transform: 'rotate(-2deg)',
        marginTop: '-2rem',
      }}
    >
      <H3>
        Dear Family & Friends,
        <br />
        We figured it out: <br />
      </H3>
      <P>
        <Center>
          <em>We're better together</em>
        </Center>
      </P>
      <H3>
        We would love it if y'all could join us in Sydney, Australia to
        celebrate our union.
        <br />
        <br />– Drew & Mieke.
      </H3>
    </div>
  </Section>
);

const Names: FC<ID> = (props) => (
  <Section {...props}>
    <H1 centered stack={false}>
      Andrew Gene Bourne
      <br /> & <br />
      Mieke Fry Schmitz
    </H1>
  </Section>
);

/*
- Nov 23, 2013: Met at a sports bar.
- Jan 20, 2014: Officially together
- Lots of travel, US, Australia, Italy, Croatia, Slovenia
- Lots of laughs
- Lots of love, Sorcha, Sunshine, Miss Monster
- Jul 7, 2018: Engaged while hiking in Maui
- Look at this stuff, isn't it neat, wouldn't you think my collection is complete.
- Madlib songs to our cats

---

In November 2013 we met at a sports bar in Brooklyn, New York when Mieke appeared to steal 2 beers from another patron and Drew needed to know more about this assertive, sporty Texan.

Since then we've have had five years of adventures across the US, Australia, Italy, Slovenia and Croatia. Maui, Perth and Ljubljana are some of our favorite locations.

We've shared the pain of losing Sorcha, (Mieke's much loved cat), and the joy of welcoming Miss Monster (Drew's first cat) into our home.

In July 2018 while out hiking in Maui, Hawaii Drew proposed to Mieke, asking "would you like to do us for forever?", Mieke interpreted his aussie mumble as "go hiking forever", leading to a moment of confusion for both of us before happily accepting.

---

<Ul>
  <Li>
    US: Maui, San Antonio, Austin, Los Angeles, Chicago, Murtle Beach.
  </Li>
  <Li>Italy: Venice, Bologna, Rome, Florence, Trieste. </Li>
  <Li>Croatia: Pula, Medulin, Rovinj, Zagreb. </Li>
  <Li>Slovenia: Ljubljana.</Li>
  <Li>Australia: Brisbane, Melbourne, Sydney, Perth. </Li>
</Ul>

*/
const OurStory: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Our Story</H3>
    <P variant="small">
      In November 2013 we met at a sports bar in Brooklyn watching our favorite
      teams - the San Antonio Spurs playing basketball, and the NY Rangers
      playing ice hockey.
    </P>
    <P variant="small">
      Since then we've have had five years of adventures across the US,
      Australia, Italy, Slovenia and Croatia. Our favorite places have been
      Maui, Perth and Ljubljana.{' '}
    </P>
    <P variant="small">
      We've shared the pain of losing Sorcha - Mieke's much loved cat - and the
      joy of welcoming Miss Monster - Drew's first cat - into our home.
    </P>
    <P variant="small">
      In July 2018 while hiking in Maui, Hawaii Drew proposed to Mieke, asking
      "would you like to do us for forever?"
      <br /> Mieke interpreted his aussie mumble as "go hiking forever", leading
      to a moment of confusion for both of us before happily accepting to a
      better adventure together.{' '}
    </P>
  </Section>
);

const Wedding: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Wedding</H3>
    <P>21st March, 2019</P>

    <H3>Ceremony</H3>
    <P>5:30pm</P>
    <P>Outdoor, Sunshine, Beach</P>

    <H3>Reception</H3>
    <P>6:30pm</P>
    <P>Dinner, Drinks and Dancing</P>
  </Section>
);

const Location: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Location</H3>
    <P>
      The Blue Room Bondi
      <br />
      Bondi Surf Lifesaving Club
      <br />
      Queen Elizabeth Dr
      <br />
      Bondi Beach NSW 2026
    </P>
    <P variant="small">Phone: +61 8978 9209</P>

    <H3>Map</H3>
    <Iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13247.943114767431!2d151.2779993!3d-33.8900201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5b734597dc5a5941!2sThe+Blue+Room+Bondi!5e0!3m2!1sen!2sus!4v1535596082195"
      width={['calc(100vw - 4rem)', '30rem', '40rem']}
      height={450}
      style={{ border: 'none' }}
    />
  </Section>
);

const Contact: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Contact</H3>
    <P variant="small">
      <A href={`mailto:drew@firstbourne.com`}>drew@firstbourne.com</A>
      <br />
      <A href="tel:+1 917-444-6951">+1 917-444-6951</A>
    </P>
  </Section>
);

const RSVP: FC<ID> = (props) => (
  <Section {...props}>
    <H3>RSVP</H3>
    <P variant="small">Please reply by February 7, 2019.</P>
    <Link to="/rsvp">Follow this link to send us your reply.</Link>
  </Section>
);

// {/* <P variant="small">
// We appreciate most of you are traveling to Sydney from elsewhere in
// Australia or the United States.
// </P> */}

const Registry: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Registry</H3>
    <P variant="small">
      If you'd like to contribute to our travel adventures, our home or our
      favorite charities, please{' '}
      <A href="https://www.zola.com/registry/drewmieke">visit our registry</A>.
    </P>
  </Section>
);

const Theme: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Theme</H3>
    <P>Ocean (Blue/Navy Blue/Green)</P>
    <P>Harmony Day (Orange)</P>
  </Section>
);

const Attire: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Attire</H3>
    <P>Cocktail.</P>
  </Section>
);

const Travel: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Travel</H3>
    <P>
      Flying from the US? <br />
      Fly <A href="https://qantas.com/">Qantas</A>.
    </P>
    <P variant="small">
      The flights are unusually cheap right now, and their staff, seats, food,
      etc have been excellent every time we've flown with them.
    </P>
    <P>Get a Visa</P>
    <P variant="small">
      You'll need a visa to enter Australia. Use the{' '}
      <A href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-finder/visit">
        Visa Finder
      </A>{' '}
      to find the right visa type. It will likely be a "Electronic Travel
      Authority (Subclass 601)", costs $20 online. Seriously, do this before you
      get to the airport.
    </P>
  </Section>
);

const Accommodation: FC<ID> = (props) => (
  <Section {...props}>
    <H3>Accommodation</H3>
    <P variant="small">
      We'll be staying at an AirBnb{' '}
      <NoBreak>at the south end of Bondi Beach</NoBreak>.
    </P>

    <P>Locations</P>
    <P variant="small">
      <A href="https://goo.gl/maps/pizJGRf5cvz">Bondi</A> to be close to the
      beach
    </P>
    <P variant="small">
      <A href="https://goo.gl/maps/WGNEDdJsxKt">Bondi Junction</A> for shopping
      and public transit options
    </P>
    <P variant="small">
      <A href="https://goo.gl/maps/E3QKLmNGHi72">George Street, Haymarket</A> to
      be in the city center
    </P>

    <P>Hotels</P>
    <P variant="small">
      <A href="https://goo.gl/maps/8TPsEP1e8VN2">
        Adina Apartment Hotel Bondi Beach Sydney
      </A>
      <br />
      69/73 Hall St, Bondi Beach NSW 2026, Australia
    </P>
    <P variant="small">
      <A href="https://goo.gl/maps/LXZZXLZDZyJ2">
        Meriton Suites Bondi Junction
      </A>
      <br />
      97 Grafton St, Bondi Junction NSW 2022, Australia
    </P>
  </Section>
);

const Activities: FC<ID> = (props) => {
  const activities = [
    {
      label: 'City',
      items: [
        'Sydney Opera House - see it up close, see it from far away',
        'Sydney Harbour Bridge - see it, climb it',
        'The Rocks – historical district',
        'Circular Quay – ferries around Darling Harbour',
        <span>
          <A href="https://maas.museum/powerhouse-museum/">Powerhouse Museum</A>{' '}
          – tech, science, creativity
        </span>,
        <span>
          <A href="https://www.bigbustours.com/en/sydney/sydney-routes-and-tour-maps/">
            Big Bus Tours
          </A>
          – City Tour, Bondi Tour
        </span>,
        <A href="https://taronga.org.au/sydney-zoo">Taronga Zoo</A>,
      ],
    },
    {
      label: 'Bondi',
      items: ['Bondi Beach', 'Restaurants'],
    },
    {
      label: 'Manly',
      items: [
        'Manly Beach',
        <A href="https://www.qstation.com.au/">
          Q Station – old quantine station, history, ghost tours
        </A>,
        'North Head - bushwalk, hike',
        <A href="https://4pinesbeer.com.au/">4 Pines Brewery</A>,
      ],
    },
    {
      label: 'Blue Mountains',
      items: [
        <A href="https://www.australia.com/en-nz/places/blue-mountains/three-sisters.html">
          Three Sisters, Katoomba
        </A>,
        <A href="https://www.bluemts.com.au/info/thingstodo/bushwalks/">
          Bushwalks / Hikes
        </A>,
        <A href="https://www.bestsydneywalks.com/blue-mountains-walks/">
          Bushwalks / Hike suggestions
        </A>,
        <A href="https://www.jenolancaves.org.au/">Jenolan Caves</A>,
      ],
    },
    {
      label: 'Breweries',
      items: [
        <A href="https://www.younghenrys.com/home">Young Henrys</A>,
        <A href="https://4pinesbeer.com.au">4 Pines Beer</A>,
        <A href="https://www.batchbrewingcompany.com">Batch Brewing Co</A>,
        <A href="https://sauce.beer/">Sauce Brewing Co,</A>,
        <A href="https://stockadebrewco.com.au/">Stockade Brewing Co</A>,
      ],
    },
    {
      label: 'Hunter Valley',
      items: ['Wineries', 'Breweries', 'Cheese', 'Farms'],
    },
    // {
    //   label: 'Music',
    //   items: ['Butterfingers (March 16)', "Fat Freddy's Drop (March 17)"],
    // },
  ];

  return (
    <Section {...props}>
      <H3>Activities</H3>
      <P variant="small">Sydney and New South Wales</P>
      {activities.map((group) => (
        <div>
          <P>{group.label}</P>
          {group.items.map((item) => (
            <P variant="small">{item}</P>
          ))}
        </div>
      ))}
    </Section>
  );
};

export const pageQuery = graphql`
  query {
    ring: file(relativePath: { eq: "IMG_4146.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drewmieke: file(relativePath: { eq: "IMG_4645.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hawaii: file(relativePath: { eq: "IMG_6596.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theme: file(relativePath: { eq: "IMG_6104.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const NavLayout = styled('nav')({});
const NavList = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  listStyle: 'none',
});
const NavItem = styled('li')({
  width: '50%',
});

const Nav: FC<ID> = (props) => (
  <NavLayout {...props}>
    <NavList>
      <NavItem>
        <A href="#ceremony">Ceremony</A>
      </NavItem>
      <NavItem>
        <A href="#location">Location</A>
      </NavItem>
      <NavItem>
        <A href="#rsvp">RSVP</A>
      </NavItem>
      <NavItem>
        <A href="#registry">Registry</A>
      </NavItem>
      <NavItem>
        <A href="#theme">Theme</A>
      </NavItem>
      <NavItem>
        <A href="#travel">Travel</A>
      </NavItem>
      <NavItem>
        <A href="#accommodation">Accommodation</A>
      </NavItem>
      <NavItem>
        <A href="#activities">Activities</A>
      </NavItem>
      <NavItem>
        <A href="#contact">Contact</A>
      </NavItem>
    </NavList>
  </NavLayout>
);

const Index = ({ data }) => (
  <React.Fragment>
    <Helmet>
      <title>Drew & Mieke / Mieke & Drew</title>
      <link
        href="https://fonts.googleapis.com/css?family=Amatic+SC"
        rel="stylesheet"
      />
    </Helmet>
    <Border>
      <Background>
        <Content>
          <Amatic>
            <Img fluid={data.ring.childImageSharp.fluid} rotate={-1} />
            <Divider index={0} />

            <Names id="names" />
            <Divider index={0} />
            <Nav id="nav" />

            <Intro id="introduction" />
            <Divider index={1} />

            <OurStory id="our-story" />
            <Divider index={1} />

            <Wedding id="ceremony" />
            <Divider index={1} />

            <Img fluid={data.hawaii.childImageSharp.fluid} />
            <Divider index={2} />

            <Location id="location" />
            <Divider index={2} />

            <RSVP id="rsvp" />
            <Divider index={3} />

            <Registry id="registry" />
            <Divider index={3} />

            <Theme id="theme" />
            <Attire />

            <Divider index={3} />
            <Img fluid={data.theme.childImageSharp.fluid} rotate={-0.5} />

            <Divider index={4} />

            <Travel id="travel" />
            <Divider index={3} />

            <Accommodation id="accommodation" />
            <Divider index={2} />

            <Activities id="activities" />

            <Divider index={4} />

            <Contact id="contact" />

            <Img fluid={data.drewmieke.childImageSharp.fluid} />
          </Amatic>
        </Content>
      </Background>
    </Border>
  </React.Fragment>
);

export default Index;
